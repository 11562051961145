import classnames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const DropdownButtonWrapper = styled.div`
	&.hostjane-main__dropdown-button {
		position: relative;
		.hostjane-main__dropdown-button__btn {
			background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="4" viewBox="0 0 20 4"><path d="M0,0l4,4l4-4H0z" fill="%23212121"/></svg>')
				#fff no-repeat 100%;
			border: 1px solid #e8eaed;
			border-radius: 2px;
			box-shadow: none;
			color: #202124;
			cursor: pointer;
			display: inline-block;
			height: 36px;
			line-height: 34px;
			min-width: 72px;
			outline: 0;
			overflow: hidden;
			text-align: left;
			text-indent: 0.01px;
			text-overflow: ellipsis;
			transition: background-color 0.2s;
			vertical-align: middle;
			white-space: nowrap;
			color: #3c4043;
			max-width: 124px;
			padding: 0 31px 0 15px;
			font-weight: 500;
			&:hover {
				background-color: #f1f3f4;
			}
		}
		.hostjane-main__dropdown-button__list {
			border: 1px solid #e8eaed;
			border-radius: 2px;
			background: #fff;
			visibility: hidden;
			max-height: 304px;
			opacity: 0;
			outline: 0;
			overflow-y: auto;
			padding: 8px 0;
			pointer-events: none;
			position: absolute;
			transition: opacity 0.2s, visibility 0.2s;
			z-index: 1015;
			top: 22px;
			left: 0px;

			&::-webkit-scrollbar {
				height: 8px !important;
				width: 8px;
				cursor: pointer;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				height: 8px !important;
				width: 8px;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: rgba(128, 134, 139, 0.26);
				border-radius: 8px;
			}
			&.active {
				visibility: visible;
				opacity: 1;
				pointer-events: auto;
			}
			.hostjane-main__dropdown-button__list__item {
				display: flex;
				margin: 0;
				min-height: 48px;
				min-width: 100%;
				padding: 8px 16px;
				white-space: nowrap;
				align-items: center;
				&:hover {
					background-color: #f1f3f4;
					cursor: pointer;
				}
			}
		}
	}
`;

function useOutsideAlerter(ref, setState, stateValue) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				setState(stateValue);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, setState, stateValue]);
}

const DropdownButton = () => {
	const [isListActive, setIsListActive] = useState(false);
	const [activeLink, setActiveLink] = useState('I want');
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, setIsListActive, false);

	return (
		<DropdownButtonWrapper
			ref={wrapperRef}
			className="hostjane-main__dropdown-button"
		>
			<button
				type="button"
				className="hostjane-main__dropdown-button__btn"
				onClick={() => setIsListActive(!isListActive)}
			>
				<span> {activeLink} </span>
			</button>
			<ul
				className={classnames(
					'hostjane-main__dropdown-button__list',
					isListActive && 'active'
				)}
			>
				{[
				    { link: 'help hiring someone', href: 'https://help.hostjane.com/getting-started/fulfillment-by-hostjane/' },
					{ link: 'to view my orders', href: 'https://www.hostjane.com/marketplace/orders' },
					{ link: 'to view my quotes', href: 'https://www.hostjane.com/marketplace/messages' },
					{ link: 'to raise a dispute', href: 'https://www.hostjane.com/marketplace/disputes' },

				].map(({ link, href }) => {
					return (
						<li
							onClick={() => {
								window.open(href, '__blank');
								setActiveLink(link);
								setIsListActive(!isListActive);
							}}
							key="link"
							className="hostjane-main__dropdown-button__list__item"
						>
							{link}
						</li>
					);
				})}
			</ul>
		</DropdownButtonWrapper>
	);
};

export default DropdownButton;
